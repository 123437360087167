.modal-dialog {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.modal-content {
  background-color: #1f1f1f;
  text-align: center;
}

.modal-body {
  padding-left: 2rem;
  padding-right: 2rem;
}

.modal-header, .modal-footer {
  border: none;
}

.code-error-warning {
  color: red;
}