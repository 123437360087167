.video-panel {
  width: 100%;
  height: 56.25vw;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-size: 100%;
}

.lock-icon {
  height: 20vw;
  color: white;
}

.video-panel-text-container {
  height: 20%;
  display: flex;
  flex-direction: column;
  font-size: 3.7vw;
}