.logo {
  height: 10rem;
}

.logo-img {
  height: 10rem;
}

.show-title {
  color: #E0CCBE;
  font-family: "Playfair Display";
  margin-left: 1rem;
  text-align: left;
  font-size: 1.3rem;
}
.company-name {
  color: #E0CCBE;
  font-family: "Playfair Display";
  margin-left: 1rem;
  text-align: left;
  font-size: 1.3rem;
}
.ticket-info {
  text-align: end;
}

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  margin: 1rem;
}