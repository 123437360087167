body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body {
  background-color: #1f1f1f;
}

.push-right {
  margin-left: auto;
}

.push-left {
  margin-right: auto;
}

.countdown {
  color: #FFFFFF;
  font-size: 2rem;
}

h1, h2, h3, h4, h5, h6 {
  color: #ffffff;
}

hr {
  border: 1px solid #E0CCBE;
  width: 80%;
}

.card {
  background-color: #282c34;
}

.header-link, a:link, a:visited {
  color: #E0CCBE;
  text-decoration: none;
}

a:hover {
  color: #c2b1a5;
  text-decoration: underline;
}

.header-link a:hover {
  color: #E0CCBE;
  text-decoration: none;
}