.info-panel {
  background-color: #191919;
  border-radius: 5px;
  width: auto;
  margin: 1rem;
  padding: 1rem;
  text-align: left;
}

.show-note-container {
  max-height: 30vh;
  overflow-y: auto;
}

.show-note-piece-container {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.show-note-item-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.show-note-item-title {
  min-width: 20vw;
  align-content: flex-start;
  margin-right: 1rem;
}

.show-note-item-title > h5 {
  display: inline;
}

.show-note-item-content {
  min-width: 60vw;
}